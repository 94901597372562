// Functions first
@import "../../../node_modules/bootstrap/scss/functions";

// Variable overrides second
@import "_variables";

// Required Bootstrap imports
@import "../../../node_modules/bootstrap/scss/variables";
$enable-dark-mode: false;
@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/root";

// Optional
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/helpers";
@import "../../../node_modules/bootstrap/scss/utilities/api";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/tables";

//Ours....
@import "_breakpoints";
@import "_layout";
@import "_colors";
@import "_mixins";
@import "_nav";
@import "_buttons";
@import "_text";
@import "_forms";
@import "_breadcrumb";
@import "_footer";

// Entity Specific
@import "_truck";
@import "_station";

// Specific Components
@import "_date_timeline_slider";
@import "_delta_graph";
@import "_details_modal";
@import "_line_graph";
@import "_info_tile";
@import "_fuel_bar";
@import "_history_dots";
@import "_list";
@import "_map";
@import "_modal";
@import "_paginator";
@import "_popover";
@import "_search-complete";
@import "_timeline_slider";
@import "_timescale_slider";
@import "_tags";

body, html{
  margin: 0;
  min-height: 100vh;
  @include gradientBg;
}


.mobile-hide {
  display: block;
}

th, td {
  &.mobile-hide {
    display: table-cell;
  }
}

.mobile-show {
  display: none;
}

@include respond-to-max('tablet') {
  .mobile-hide {
    display: none !important;
  }
  .mobile-show {
    display: block;
  }
}

// in bootstrap but missing?
.justify-content-center {
  justify-content: center;
}
.text-center {
  text-align: center;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

}

.sidebar {
  .actions {
    .btn {
      width: 100%;
      text-align: center;
      margin: 0 auto 1rem 0;
      border-radius: $cornerRadius;
    }
  }
}

.content-wrapper {
  font-size: 1.4rem;
  @include box;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  .action-icons {
    flex: 0 0 2rem;
    a {
      width: 3.6rem;
      height: 3.6rem;
      border-radius: 50%;
      background-color: $optimus-blue-10;
      color: $optimus-blue;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2rem 0;
      transition: background-color .3s ease;
      i, svg {
        opacity: .5;
        transition: opacity .3s ease;
        &.fa-edit {
          position: relative;
          left: .125em;
          top: -.1em;
        }
      }
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &:hover, &:focus {
        background-color: $optimus-blue;
        color: white;
        i, svg {
          opacity: 1;
        }
      }
    }
  }
  &.create-station-price-wrapper,
  &.edit-station-price-wrapper {
    width: 40%;
    margin-top: 4rem;
    .flex-form {
      flex-direction: column;
      margin: auto 2rem;
    }
  }
}

.sidebar-layout {
  display: flex;
  flex-direction: row;
  .side-nav {
    flex-basis: 200px;
    display: flex;
    flex-direction: column;
    a {
      padding: .5rem 1rem;
      border-left: solid 4px transparent;
      position: relative;
      z-index: 2;
      &.active {
        border-left-color: $optimus-blue;
        &:before {
          display: none;
        }
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        left: -3px;
        top: 0;
        width: 2px;
        height: 100%;
        background-color: $optimus-blue-20;
      }
    }
  }
  .side-content {
    flex: 1 1 auto;
    margin-left: 1rem;
  }
  @include respond-to-max('tablet') {
    flex-direction: column;
    .side-nav {
      flex-basis: 100%;
      margin-bottom: 1rem;
      background-color: $optimus-blue;
      border-radius: $cornerRadius;
      // position: relative;
      a {
        border-left: none !important;
        color: white;
        text-decoration: none;
        padding-left: 2rem;
        // position: initial;
        &:before {
          display: none !important;
        }
        &.active {
          &:after {
            content: '';
            display: block;
            position: absolute;
            z-index: 1;
            right: 1.5rem;
            top: 1.5rem;
            border: .7rem solid transparent;
            border-top-color: white;
          }
        }
      }
      .inactive {
        display: none;
      }
      &.open {
        padding-top: 1rem;
        padding-bottom: 1rem;
        .inactive {
          display: block;
        }      
      }
    }
    .side-content {
      margin-left: 0;
    }
  }
}

.two-column {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1 1 auto;
  & > div {
    flex: 0 0 48%;
    min-width: 350px;
  }
}

.chart-wrapper {
  @include box;
  background-color: $optimus-blue-10;
}

.box {
    @include box;
    &.dashboard-box {
      background-color: transparent;
    }
}

.box-blue {
  @include box;
  background-color: $optimus-blue-10;
  padding-top: 1rem;
  margin-bottom: 2rem;
  @include respond-to-max('tablet') {
    padding: 1rem;
  }
}

.clickable {
    &:hover, &:focus {
        color: $optimus-blue;
        cursor: pointer;
        text-decoration: solid 2px underline;
    }
}

.table {
  td {
    vertical-align: middle;
  }
  .table-header {
    display: flex;
    justify-content: space-between;
    h2 {
      color: $optimus-blue;
      background-color: transparent;
    }
  }
  .table-wrapper {
    @include box;
  }
  .table-row {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.4rem;
    align-items: center;
    &:nth-child(odd) {
      background-color: rgba(200,200,200,.125);
    }
    & > div {
      padding: 0 1em;
      @include flexWidth(33, 25, 50, auto);
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
      .btn {
        margin: .5em 0;
        font-size: 1rem;
      }
    }
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  span, a {
    display: flex;
    margin-left: .75rem; 
    color: $optimus-blue-20;
    background-color: $optimus-blue-10;
    // padding: .5rem;
    width: 3.35rem;
    height: 3.35rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    i, svg {
      font-size: 1.75rem;
    }
    &:hover, &:focus {
      color: $optimus-blue;
    }
  }
}

.details-block {
  @include box;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  position: relative;
  h1 {
    margin-bottom: 0;
  }
  h2 {
    font-weight: 300;
    font-size: 1.8rem;
  }
  .detail {
    padding: .5rem;
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
    label {
      margin-right: 1rem;
      letter-spacing: .05em;
    }
    span {
      font-weight: 800;
      letter-spacing: .05em;
    }
  }
  @include respond-to-max('tablet') {
    padding: 1rem;
  }
}

.inline-detail {
  label {
    text-transform: uppercase;
    color: $dark-grey;
    font-size: 1rem;
  }
  span {
    display: block;
    font-weight: 600;
  }
}

.large-status-icon {
  color: $optimus-blue;
  font-size: 5rem;
  img {
      width: 80%;
  }
}

#public-wrapper, #app-wrapper, #admin-wrapper {
  max-width: 1600px;
  margin: 0 auto;
}

#app-wrapper {  
  display: flex;
  padding-bottom: 10rem;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  @include respond-to-max('laptop') {
    flex-direction: column;
    padding-bottom: unset;
  }
  .app-content {
    margin: 6rem 1rem auto 1rem;
    width: calc(100% - 250px);
    @include respond-to-max('laptop') {
      margin: 1rem 0 2rem 0;
      flex-direction: column;
      width: 100%;
      #breadcrumb {
        display: none;
      }
    }
  }
  #breadcrumb {
    margin-top: 1rem;
  }
}

.field-choices {
  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
  column-count: 3;
  .field-option {
    // flex-basis: 24%;
    // margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid $optimus-blue-20;
    border-radius: 4px;
    padding: .5rem 1rem;
    background-color: white;
    font-size: 1.25rem;
    font-weight: 400;
    break-inside: avoid;
    &.selected {
      background-color: $optimus-blue;
      color: white;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    &.not-selected {
      background-color: $light-grey;
      color: $optimus-blue-50;
      &:hover {
        background-color: $ex-light-grey;
        color: $optimus-blue;
        cursor: pointer;
      }
    }
    &.hide {
      display: none;
    }
  }
}