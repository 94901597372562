.info-tile {
    width: 100%;
    min-height: 10rem;
    padding: 4rem 1rem 2rem 1rem;
    border-radius: $cornerRadius;
    background-color: $optimus-blue-10;
    text-align: center;
    color: $optimus-blue;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-bottom: 1rem;
    .title {
        position: absolute;
        top: 1rem;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 1rem;
        font-weight: 600;
    }
    .value {
        font-size: 2.4rem;
        line-height: .75;
        font-weight: 700;
    }
    .label {
        font-size: 1.4rem;
        font-weight: 700;
    }
    .inline-dropdown {
        position: absolute;
        bottom: .5rem;
        left: .5rem;
        opacity: .5;
        .form-control {
            background-color: transparent;
            color: white;
            font-weight: 600;
        }
    }
    @include respond-to-max('tablet') {
        flex: 0 0 24%;
        width: 24%;
    }
    @include respond-to-max('mobile') {
        flex: 0 0 49%;
        width: 49%;
    }
    @include respond-to-max('small') {
        flex: 0 0 100%;
        width: 100%;
    }
    &.large {
        background-color: $optimus-blue-50;
        color: white;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 3rem;
        .title {
            position: relative;
            width: auto;
            font-size: 1.8rem;
            font-weight: 600;
            top: unset;
        }
        .value, .label {
            display: inline;
        }
        .label {
            font-weight: 600;
            margin-left: .5em;
        }
        .inline-dropdown {
            left: 3rem;
        }
        @include respond-to-max('tablet') {
            flex: 1 1 100%;
            width: 100%;
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
        @include respond-to-max('mobile') {
            flex-direction: column;
            text-align: center;
            min-height: 8rem;
            padding: 1.5rem 1rem;
            justify-content: center;
            .inline-dropdown {
                bottom: .25rem;
            }
        }
    }
}