form {
    width: 100%;
    ::placeholder {
        color: $medium-grey !important;
    }
    &.flex-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .form-group {
            @include flexWidth(100, 33, 25, 50, auto);
            &.flex-100 {
                flex-basis: 100%;
            }
            @include respond-to-max('tablet') {
                flex: 1 1 100% !important;
            }
        }
    }
    .form-section-header {
        width: 100%;
        flex: 1 1 100%;
        margin: 2rem 0 1rem 0;
        padding: .5rem 1rem .5rem .5rem;
        background-color: $offwhite;
        border-left: .5rem solid $optimus-blue;
        font-weight: 600;
    }
    .form-group {
        text-align: left;
        margin-bottom: 1rem;
        label {
            display: block;
            text-transform: uppercase;
            font-weight: 400;
            color: $medium-grey;
            letter-spacing: .05em;
        }
        input[readonly] {
            background-color: $light-grey;
            pointer-events: none;
        }
        .form-control {
            font-size: 1.6rem;

        }
        .input-message {
            text-align: center;
            padding: .5rem;
            background-color: $light-grey;
        }
        &.check-form {
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding: 1.25rem 0 0 0;
            margin: 0;
            display: flex;
            .form-check-input {
                margin: 0 1rem 0 0;
                height: 2rem;
                width: 2rem;
                // border-color: $optimus-blue;
                &:checked {
                    background-color: $optimus-blue;
                }
            }
            &.disabled {
                .form-check-label {
                    color: #aeaeae;
                }
                .form-check-input {
                    display: none;
                    }
                }
        }
        &.search-select {
            .search-select-wrapper {
                display: flex;
                .search-select-input {
                    flex: 1 1 auto;
                }
                .search-select-active {
                    align-self: flex-end;
                    padding-bottom: .5rem;
                    .active-item {
                        background: $optimus-blue;
                        padding: .5rem .75rem;
                        color: white;
                        border-radius: 6px;
                        margin: .5rem;
                        svg, i {
                            margin-left: 1rem;
                        }
                    }
                }
                &.selected {
                    flex-direction: column;
                    justify-content: flex-start;
                    .search-select-active {
                        align-self: flex-start;
                        .active-item {
                            margin: 0 .5rem 0 0;
                            display: block;
                        }
                    }
                }
            }
        }
    }
    .invalid-feedback {
        display: none;
    }
    .validation-errors {
        margin: 20px auto 4px;
        p {
            font-size: 1.1rem !important;
            margin-bottom: .5rem !important;
            color: red;
        }
    }
}

.inline-edit {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-right: 2rem;
    .form-group {
        flex: 1 1 auto;
        .form-label {
            display: none;
        }
        input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    .action-icons {
        flex: 0 0 90px;
    }
    button {
        flex: 1 1 auto;
        border: none;
        border-top: 1px solid $medium-grey;
        border-bottom: 1px solid $medium-grey;
        color: $optimus-blue;
        padding: 0;
        font-size: 2.15rem;
        width: 2em;
        border-right: 1px solid $medium-grey;
        &[type="submit"] {
            color: green;
            &:hover, &:focus {
                background-color: green;
                color: white;
            }
        }
        &.cancel {
            color: $optimus-red;
            &:hover, &:focus {
                background-color: $optimus-red;
                color: white;
            }
        }
    }
}

.form-group {
    &.inline-dropdown {
        display: flex;
        .form-label {
            margin-bottom: 0;
            margin-right: .5rem;
        }
        .form-control {
            width: fit-content;
            padding: 0;
            border: none;
        }
    }
}