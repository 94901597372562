.station {
    margin-bottom: 1rem;
    .station-header {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: space-between;
        @include box;
        padding: 3rem 2rem 1.5rem 2rem;
        .station-details {
            display: flex;
            flex: 0 0 100%;
            justify-content: space-between;
            .station-details-block, .station-status-block {
                flex: 0 0 calc(50% - 2rem);
            }
            .station-status-block {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
        .status-detail {
            display: flex;
            padding: .5rem 1rem;
            margin-bottom: .5rem;
            align-items: center;

            .label {
                flex: 0 0 11rem;
                font-size: 1.4rem;
                font-weight: 400 !important;
                color: $dark-grey;
            }
            .value-wrapper {
                span {
                    font-size: 2.4rem;
                    font-weight: 600;
                    color: $optimus-blue;
                }
                a {
                    display: inline-block;
                    margin-left: 1rem;
                    font-weight: 400;
                    font-size: 1rem;
                    text-decoration: underline;
                }
                .small {
                    font-size: 1.2rem;
                    font-weight: 300;
                    margin-left: .5rem;
                }
                .info-wrapper {
                    color: $dark-grey;
                    font-size: 1rem;
                    text-align: left;
                    justify-content: flex-start;
                }
            }
        }
        .info-actions {
            position: relative;
            right: -1rem;
            bottom: -1rem;
            display: flex;
            justify-content: flex-end;
            .last-updated {
                text-align: right;
                font-weight: 400;
                color: $medium-grey;
                padding-right: .5rem;
                margin-right: .5rem;
                border-right: solid thin $medium-grey
            }
        }
    }
    .modal {
        .modal-backdrop {
            z-index: 12;
        }
    }
    @include respond-to-max('mobile') {
        .station-header {
            padding: 1rem 2rem 2.5rem 1rem;
            .station-details {
                flex-direction: column;
            }
        }
    }
}