// NOTE: Putting color variations as &... until we need to break them out into their own
.delta-graph {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1.5rem 2rem;
    .label {
        color: $dark-grey;
        font-weight: 600;
        font-size: 1.2rem;
    }
    .delta-graph-wrapper {
        flex: 1 1 auto;
        .delta-graph-line {
            width: 100%;
            position: relative;
            height: .75rem;
            &::before {
                content: '';
                display: block;
                position: absolute;
                height: .4rem;
                top: .4rem;
                left: 0;
                width: 100%;
                background-color: $light-grey;
                border-radius: .3rem;
                overflow: hidden;
            }
            .delta-graph-item {
                position: absolute;
                left: 0;
                top: .2rem;
                min-width: 3px;
                height: .8rem;
                background-color: $dark-grey;
                border-radius: .5rem;
                &:hover {
                    span {
                        display: block;
                    }
                }
                span {
                    font-size: 1.2rem;
                    display: none;
                    position: absolute;
                    width: 12rem;
                    background-color: white;
                    left: -6rem;
                    bottom: 1.5rem;
                    padding: 1rem;
                    border-radius: .5rem;
                    text-align: center;
                    font-weight: 300;
                    @include shadow;
                    label {
                        font-size: 1.2rem;
                        font-weight: 600;
                        margin-bottom: .5rem;
                        display: block;
                    }
                    &:after {
                        position: absolute;
                        left: calc(50% - .125rem);
                        bottom: -1rem;
                        content: '';
                        display: block;
                        border: .5rem solid transparent;
                        border-top-color: white;
                    }
                }
            }
        }
    }
    &.engine-state {
        .delta-graph-wrapper {
            // .delta-graph-line {
            //     &::before {
            //         background-color: $optimus-blue-20;
            //     }
            // }
            .delta-graph-item {
                background-color: $optimus-blue;
                span {
                    width: 24rem;
                    left: calc(50% - 12.35rem);
                }
            }
        }
    }
    &.e-stops {
        .delta-graph-wrapper {
            // .delta-graph-line {
            //     &::before {
            //         background-color: $optimus-red-20;
            //     }
            // }
            .delta-graph-item {
                background-color: $optimus-red;
                width: .8rem;
            }
        }
    }
}
