.list {
    .list-header {
        margin-bottom: 2rem;
        font-weight: 600;
    }
    .list-item {
        font-size: 1.6rem;
        font-weight: 400;
        color: $dark-grey;
        display: flex;
        align-items: center;
        border-radius: 1rem;
        @include shadow;
        padding: 2rem;
        background-color: white;
        justify-content: space-between;
        margin: 1rem 0;
        .list-item-left {
            display: flex;
            align-items: center;
            max-width: 300px;
            flex-wrap: nowrap;
            .icon {
                margin-right: 2rem;
                flex: 0 0 2rem;
            }
            .owner-label {
                display: flex;
                flex-direction: column;
                margin-right: 2rem;
                overflow: hidden;
                max-width: 150px;
                span, strong {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .fault-label {
            margin-right: 2rem;
            &.level-1 {
                color: $optimus-red;
            }
            &.level-2 {
                color: $optimus-orange;
            }
        }
        .action-icon {
            flex: 0 0 4rem;
            margin-left: 2rem;
        }
    }
    .see-more {
        display: block;
        font-weight: 600;
        margin: 2rem auto;
        text-align: center;
        color: $blue-md;
        &:focus, &:hover {
            color: $optimus-blue;
            cursor: pointer;
            text-decoration: underline;
        }
    }
}