@import "_colors";

.search-complete {
    // margin-top: 1rem;
    // label {
    //     display: block;
    //     font-size: 1.4rem;
    //     color: $dark-grey;
    //     font-weight: 500;
    //     margin-left: .5rem;
    // }
    .search-complete-wrapper {
        position: relative;
        // input {
        //     padding: .5rem 1rem;
        //     border: 1px solid $optimus-blue;
        //     font-size: 1.6rem;
        //     width: 80%;
        // }
        .results-list {
            position: absolute;
            z-index: 2;
            top: 4rem;
            left: 0;
            background-color: white;
            border: 1px solid $optimus-blue;
            font-size: 1.4rem;
            .result-item {
                padding: .5rem 1rem;
                border-bottom: 1px solid $optimus-blue;
                .disabled {
                    color: $medium-grey;
                }
                &:hover {
                    cursor: pointer;
                    color: $optimus-blue;
                    background-color: $ex-light-grey;
                }
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}