@mixin nivoColorToggles($color) {
    background-color: $color;
    border-color: $color;
    color: $color;    
}

.line-graph {
    // display: flex;
    // flex-direction: column;
    background-color: rgba(10, 106, 169, .02);
    border: 1px solid rgba(10, 106, 169, 0.1);
    padding: 1rem 1.5rem;
    border-radius: 5px;
    margin: 2rem 0;
    align-items: center;
    .point-label {
        display: flex;
        justify-content: space-between;
        label {
            margin-right: .5rem;
        }
    }
    .point-time {
        width: 100%;
        text-align: right;
        border-top: thin solid $medium-grey;
        color: $medium-grey;
        padding-top: .5rem;
        margin-top: .5rem;
        font-weight: 300;
    }
    .label-wrapper {
        // flex: 0 0 10rem;
        .label {
            color: $dark-grey;
            font-weight: 600;
            font-size: 1.2rem;
            margin-bottom: .5rem;
            padding-top: .25rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // border-top: 2px solid $optimus-blue-10;
        }
        .line-graph-toggles {
            position: relative;
            .trigger {
                font-size: 1rem;
                display: flex;
                align-items: center;
                cursor: pointer;
                opacity: .5;
                i, svg {
                    margin-right: .25rem;
                }
                &:focus, &:hover {
                    color: $optimus-blue;
                    opacity: 1;
                }
            }
            .toggles {
                display: none;
                position: absolute;
                width: fit-content;
                left: 5rem;
                top: 0;
                background-color: white;
                z-index: 100;
                padding: .5rem 1rem;
                @include shadow;
                & > div {
                    white-space: nowrap;
                    // background-color: $light-grey;
                    border: thin solid $light-grey;
                    padding: .5rem 1rem;
                    border-radius: 1rem;
                    margin: .5rem 0;
                    &.inactive {
                        // background-color: $dark-grey;
                        background-color: white !important;
                    }
                    &.active {
                        color: white !important;
                    }
                }
                // NOTE: Just mapping Nivo Colors based on nth-child here
                &.spectral {
                    & > div {
                        &:first-child {
                            @include nivoColorToggles(#9e0142);
                        }
                        &:nth-child(2) {
                            @include nivoColorToggles(#d53e4f);
                        }
                        &:nth-child(3) {
                            @include nivoColorToggles(#f46d43);
                        }
                        &:nth-child(4) {
                            @include nivoColorToggles(#fdae61);
                        }
                        &:nth-child(5) {
                            @include nivoColorToggles(#fee08b);
                        }
                    }
                }
                &.category10 {
                    & > div {
                        &:first-child {
                            @include nivoColorToggles(#1f77b4);
                        }
                        &:nth-child(2) {
                            @include nivoColorToggles(#ff7f0e);
                        }
                        &:nth-child(3) {
                            @include nivoColorToggles(#2ca02c);
                        }
                        &:nth-child(4) {
                            @include nivoColorToggles(#d62728);
                        }
                        &:nth-child(5) {
                            @include nivoColorToggles(#9467bd);
                        }
                    }
                }
            }
            &.open {
                .trigger {
                    color: $optimus-blue;
                }
                .toggles {
                    display: block;
                }
            }
        }
    }
    .line-graph-wrapper {
        flex: 1 1 auto;
    }
}