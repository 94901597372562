.btn {
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 600;
    letter-spacing: .05em;
    border: 1px solid $optimus-blue-10;
    color: $optimus-blue;
    padding: 1.25rem 2.5rem;
    margin: 1em auto;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    border-radius: $cornerRadius;
    text-decoration: none;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &:hover, &:focus {
        background-color: $optimus-blue;
        color: white;
        text-decoration: none;
        cursor: pointer;
    }
    &.white-outline {
        background: transparent;
        color: white;
        border-color: white;
        &:hover {
            background-color: white;
            color: $optimus-blue;
        }
    }
    &.blue-outline {
        background: transparent;
        color: $optimus-blue;
        border-color: $optimus-blue;
        &:hover {
            background-color: $optimus-blue;
            color: white;
        }
    }
    &.white {
        background-color: white;
        border-color: white !important;
        color: $optimus-blue !important;
        font-weight: 700;
        &:hover {
            background-color: agua;
            border-color: agua !important;
        }
    }
    &.blue {
        background-color: $optimus-blue;
        border-color: $optimus-blue !important;
        color: white !important;
        font-weight: 700;
        &:hover {
            background-color: white;
            color: $optimus-blue !important;
        }
    }
    &.grey {
        background-color: $light-grey;
        border-color: $light-grey !important;
        color: $dark-grey;
        font-weight: 700;
        &:hover {
            background-color: $light-grey;
            border-color: $optimus-blue !important;
            color: $optimus-blue !important;
        }
        &.active {
            color: white;
            background-color: $optimus-blue;
            border-color: $optimus-blue !important;
            &:hover {
                background-color: $light-grey;
            }

        }
    }
    &:disabled {
        background: $light-grey;
        border-color: $light-grey !important;
        color: $medium-grey !important;
        &:hover {
            cursor: default;
            background: $light-grey;
            color: $medium-grey !important;
        }
    }
    &.delete {
        padding: 0;
        border: none;
        float: right;
        margin: 0;
        font-weight: 400;
        &:hover, &:focus {
            background-color: transparent;
            color: $optimus-red;
            text-decoration: underline;
        }
    }
    &.cancel {
        margin-left: 1rem;
    }
    &.table {
        padding: 0.5rem 1rem;
        border-radius: 0 !important;
        border-color: none !important;
        margin: 0;
        font-size: 1.2rem;
        font-weight: 400;
        &:hover, &:focus {
            background-color: $optimus-blue;
            color: white;
            text-decoration: none;
        }
    }
}
