.date-timeline-slider-wrapper {
    display: flex;
    align-items: center;
    .form-group {
        margin-right: 4rem;
        label {
            font-size: 1.35rem;
            color: $dark-grey;
            font-weight: 600;
            margin-bottom: 0;
            padding-left: .5rem;
        }
        select {
            font-size: 1.35rem;
            line-height: 1;
            font-weight: 600;
            letter-spacing: .05em;
            border: 1px solid $optimus-blue-10;
            background-color: $optimus-blue;
            color: white;
            padding: 1.25rem 2.5rem;
            display: inline-block;
            font-family: 'Poppins', sans-serif;
            border-radius: $cornerRadius;
            text-decoration: none;
            transition: all 0.3s ease;
            position: relative;
            background-image: url(../imgs/chevron-down-solid-white.svg);
            background-repeat: no-repeat;
            background-position: right 1.5rem top 50%;
            background-size: 1.5rem;
            &:hover, &:focus {
                background-color: white;
                color: $optimus-blue;
                text-decoration: none;
                background-image: url(../imgs/chevron-down-solid-blue.svg);
                cursor: pointer;
            }
        }
    }
}