$optimus-blue: rgb(10,106,169);
$optimus-blue-50: rgba(10,106,169,.5);
$optimus-blue-20: rgba(10,106,169,.2);
$optimus-blue-20-hex: #b0cfe5;
$optimus-blue-10: rgba(10,106,169,.1);
$optimus-red: #C31D21;
$optimus-red-20: rgba(195, 29, 33, .2);

$dark-grey: rgb(102,102,102);
$medium-grey: rgb(149,149,149);
$light-grey: rgb(232, 232, 232);
$ex-light-grey: rgb(242, 242, 242);
$bg-blue: #FBFCFD;

$offwhite: #EFEFEF;

$optimus-orange: #F07A23;
$blue-md: #889EAC;

.status {
    &.low {
        background-color: yellow !important;
    }
    &.warning {
        background-color: orange !important;
    }
    &.critical {
        background-color: red !important;
        color: white;
    }
}
