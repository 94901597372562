.history-dots {
    .label {
        font-weight: 600;
        font-size: 1.2rem;
        color: $dark-grey;
        margin-bottom: 1rem;
    } 
    .info {
        display: none;
        span {
            font-weight: 600;
        }
    }
    .wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 20rem;
        .dot {
            width: 12%;
            padding-top: 12%;
            background-color: $blue-md;
            border-radius: 50%;
            margin: 1%;
            position: relative;
            &.ok {
                background-color: $optimus-blue;
            }
            &.minor {
                background-color: $optimus-orange;
            }
            &.major {
                background-color: $optimus-red;
            }
            &:not(.no-info) {
                &:hover, &:focus {
                    .info {
                        display: block;
                        background-color: white;
                        position: absolute;
                        width: 25rem;
                        height: auto;
                        right: -5rem;
                        top: calc(100% + 1rem);
                        z-index: 100;
                        font-size: 1.2rem;
                        padding: 1rem;
                        border-radius: $cornerRadius;
                        @include shadow;
                        .label {
                            border-bottom: thin solid $light-grey;
                            margin-bottom: .5rem;
                        }
                        .fault-item {
                            margin-bottom: .5rem;
                        }
                        span {
                            display: block;
                        }
                        &:before {
                            content:'';
                            display: block;
                            border: 1rem solid transparent;
                            border-bottom-color: white;
                            position: absolute;
                            right: 5.5rem;
                            top: -2rem; 
                        }
                    }
                }
            }
        }
    }
}