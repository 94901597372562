.timeline-slider-wrapper {
    width: 100%;
    min-height: 50px;
    background-color: $optimus-blue-10;
    border-radius: .5rem;
    padding-left: 1rem;
    padding-right: 2rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    .label {
        color: $optimus-blue;
        font-size: 1.6rem;
        font-weight: 600;
        width: 10rem;
    }
    .timeline-slider {
        width: calc(100% - 12rem);
        display: flex;
        position: relative;
        .marker {
            content: '';
            display: flex;
            height: 10px;
            // background: white;
            background-color: $optimus-blue-20;
            flex: 1 1 auto;
            cursor: pointer;
            position: relative;
            flex-direction: column;
            align-items: center;
            label {
                font-weight: 600;
                margin-top: 12px;
                color: $optimus-blue;
            }
            &:before, &:after {
                content: '';
                height: 16px;
                width: 16px;
                background-color: white;
                border: 4px solid $optimus-blue;
                position: absolute;
                top: -3px;
                display: none;
                border-radius: 50%;
                overflow: hidden;
            }
            &:before {
                left: -5px;
                display: block;
            }
            &:last-child {
                &:after {
                    display: block;
                    right: -10px;
                }
            }
        }
        .timeline-handle {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            transition: right .2s linear;
            height: 10px;
            background-color: $optimus-blue;
            z-index: 2;
            &:before, &:after {
                content: '';
                height: 20px;
                width: 20px;
                background-color: $optimus-blue;
                position: absolute;
                top: -5px;
                display: block;
                border-radius: 50%;
                overflow: hidden;
            }
            &:before {
                left: -5px;
            }
            &:after {
                right: -15px;
            }
        }
    }
}

.box-blue {
    .timeline-slider-wrapper {
        background-color: transparent;
        padding-top: 0;
        padding-bottom: 2rem;
    }
}