#footer-container {
    display: flex;
    justify-content: space-between;
    background-color: $optimus-blue;
    height: 10rem;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 2rem 6rem;
    color: white;
    z-index: 6;
    .footer-logo-container {
        width: 220px;
        height: 50px;
        .footer-logo {
            filter: brightness(0) invert(1);
        }
    }
    .footer-info {
        display: flex;
        flex-direction: column;
        span {
            font-size: 1rem;
            text-align: right;
            line-height: 2.2;
        }
    }
}

@include respond-to-max('laptop') {
    #footer-container {
        flex-direction: column;
        align-items: center;
        height: unset;
        position: unset;
        .footer-info {
            margin-top: 2rem;
            span {
                text-align: center;
            }
        }
    }
}