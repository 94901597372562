.details-modal {
    width: 100%;
    min-width: 400px;
    margin: 2rem 0 1rem 0;
    .detail {
        padding: 1em 0;
        border-bottom: 2px solid $light-grey;
        label {
            font-weight: 400;
            text-transform: uppercase;
            font-size: 1rem;
            color: $medium-grey;
        }
        span {
            display: block;
            font-size: 1.4rem;
            font-weight: 600;
        }
    }
}

