$grid-breakpoints: (
  xs: 350px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
);

$cornerRadius: 1rem;
$sidebarWidth: 15rem;
$sidebarGutter: 5rem;

// $sidebarWidth: (
//     xs: 100%,
//     sm: 100px,
//     md: 150px,

// );