.timescale {
    width: 100%;
    .timescale-wrapper {
        position: relative;
        height: 20px;
        width: 100%;
        .mark-wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            pointer-events: none;
            display: flex;
            justify-content: space-between;
            z-index: 1;
            .mark {
                width: .25rem;
                height: 100%;
                background-color: $light-grey;
            }
        }
        // background-color: $optimus-blue-20;
        &:after {
            content: '';
            width: 100%;
            height: .5rem;
            background-color: $light-grey;
            position: absolute;
            left: 0;
            top: calc(50% - .25rem);
            z-index: 2;
        }
        .react-draggable {
            z-index: 3;
            &:after {
                content: '';
                width: 100%;
                height: .5rem;
                background-color: $optimus-blue;
                position: absolute;
                left: 0;
                top: calc(50% - .25rem);
            }
        }
        .label-left, .label-right {
            color: $optimus-blue;
            font-weight: 600;
            font-size: 1.2rem;
            top: 2rem;
            position: absolute;
            width: 8rem;
            pointer-events: none;
        }
        .label-left {
            right: -2rem;
            text-align: right;
        }
        .label-right {
            left: -2rem;
        }
    }
}