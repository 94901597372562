.fleet-tags {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    .fleet-tag {
        padding: .4rem 1rem;
        background-color: $optimus-blue;
        color: white;
        border-radius: 4px;
        margin-right: 1rem;
        margin-bottom: .5rem;
        font-weight: 400;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .remove-tag {
            margin-left: .75rem;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .tag-search {
        width: 150px;
        margin-right: 1rem;
    }
    .edit-icon {
        color: $dark-grey;
        &:hover {
            cursor: pointer;
            color: black;
        }
    }
}