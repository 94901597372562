.map-wrapper {
    height: 600px;
    margin: 2rem auto 0 auto;
    border-radius: $cornerRadius;
    overflow: hidden;
    position: relative;
    z-index: 1;
    width: 100%;
    display: flex;
    .mapboxgl-ctrl-bottom-right {
        display: none;
    }
    .map {
        height: 100%;
        width: calc(100% - 375px);
    }
    .map-details-wrapper {
        height: 100%;
        width: 375px;
        background-color: white;
        padding: 2rem;
        .map-actions {
            display: flex;
            justify-content: space-between;
            margin-bottom: 2rem;
            .form-group {
                @include flexWidth(100, 33, 25, 50, auto);
                &.flex-100 {
                    flex-basis: 100%;
                }
                &:first-child {
                    margin-right: 1rem;
                }
                @include respond-to-max('tablet') {
                    flex: 1 1 100% !important;
                }
                label {
                    font-size: 1.35rem;
                    color: $dark-grey;
                    font-weight: 600;
                    margin-bottom: 0;
                    padding-left: .5rem;
                }
                select {
                    font-size: 1.35rem;
                    line-height: 1;
                    font-weight: 600;
                    letter-spacing: .05em;
                    border: 1px solid $optimus-blue-10;
                    background-color: $optimus-blue;
                    color: white;
                    padding: 1.25rem 2.5rem;
                    display: inline-block;
                    font-family: 'Poppins', sans-serif;
                    border-radius: $cornerRadius;
                    text-decoration: none;
                    transition: all 0.3s ease;
                    &:hover, &:focus {
                        background-color: white;
                        color: $optimus-blue;
                        text-decoration: none;
                        cursor: pointer;
                    }
                }
            }
        }
        .play-controls {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 1rem;
            .btn {
                margin: 0 1rem 0 0;
            }
            i, svg {
                &.disabled {
                    border-color: $light-grey;
                    background-color: $light-grey;
                    color: $medium-grey;
                }
            }
            .disabled, :disabled {
                pointer-events: none;
                opacity: .5 !important;
            }
        }
        .map-controls {
            width: 100%;
            position: relative;
            .scale-bar {
                margin-top: 2rem;
                margin-bottom: 2rem;
                background: $optimus-blue-20;
                position: relative;
                display: block;
                width: 100%;
                height: 10px;
                .resize-handle {
                    position: relative;
                    &:after{
                        content: '';
                        position: absolute;
                        top: -5px;
                        left: 3px;
                        width: 2px;
                        height: 20px;
                        background: black;
                    }
                }
            }
            .playtrack {
                margin-top: 2rem;
                margin-bottom: 2rem;
                width: 100%;
                height: 5px;
                background-color: $optimus-blue-20;
                position: relative;
                &.disabled {
                    pointer-events: none;
                    opacity: .2;
                    .playhead {
                        opacity: 0;
                    }
                }
                .playhead {
                    pointer-events: none;
                    content: '';
                    display: block;
                    position: absolute;
                    width: 10px;
                    height: 15px;
                    top: -5px;
                    background-color: $optimus-blue;
                }
            }
        }
    }
    .signal-details {
        & > div {
            display: flex;
            justify-content: space-between;
            border-bottom: thin solid #CCC;
            padding: .5rem 0;
            font-size: 1.2rem;
            label {
                width: 13rem;
            }
        }
    }
}