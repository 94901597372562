// initial attempt at trying to use grid. may not be worth doing using table syntax
.table-grid {
    flex: 1;
    display: grid;
    border: 1px solid;
    min-width: 100%;
    grid-template-columns: minmax(25px, max-content) repeat(auto-fit, minmax(200px, 1fr)) minmax(75px, max-content);
    &.with-index {
        grid-template-columns: minmax(25px, max-content) repeat(auto-fit, minmax(200px, 1fr));
        &.with-action {
            grid-template-columns: minmax(25px, max-content) repeat(auto-fit, minmax(200px, 1fr)) minmax(75px, max-content);
        }
    }
    &.with-action {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) minmax(75px, max-content);
    }
    grid-template-columns: minmax(25px, max-content) repeat(auto-fit, minmax(200px, 1fr)) minmax(75px, max-content);
    // grid-auto-flow: column;
    thead, tbody, tr {
        display: contents;
    }
    th {
        background-color: #DEDEDE;
        font-size: 1.2rem;
        font-weight: 500;
        align-self: center;
        padding: 5px;
    }
    td {
        padding: 5px;
        font-size: 1.4rem;
        align-self: center;
    }
}
.pagination-wrapper {
    .table {
        thead {
            tr {
                border-bottom: 1px solid $dark-grey;
                th {
                    background-color: transparent;
                    font-weight: 500;
                    font-size: 1.2rem;
                    color: $dark-grey;
                }
            }
        }
        tbody {
            tr {
                td {
                    position: relative;
                    padding: .4rem .75rem;
                    a {
                        font-size: 1.4rem;
                    }
                }
                &:hover {
                    td {
                        background-color: $optimus-blue-10;
                    }
                }
            }
        }
        &.striped {
            tbody {
                tr {
                    &:nth-child(odd) {
                        td {
                            background-color: $ex-light-grey;
                        }
                        &:hover {
                            td {
                                background-color: $optimus-blue-10;
                            }
                        }
                    }
                }
            }
        
        }
    }
}

.table, .table-striped {
    thead {
        background-color: transparent;
        tr {
            background-color: transparent;
        }
        th {
            font-size: 1rem;
            font-weight: 300;
            text-transform: uppercase;
        }
    }
    tbody {
        tr {
            &.current {
                td {
                    background-color: rgba(0, 255, 0, .1);
                    font-weight: 500;
                }
            }
            &.navigable {
                &:hover {
                    cursor: pointer;
                    background: #DEDEDE;
                }
            }
            &.price-gap {
                td {
                    background-color: orange;
                }
            }
        }
        td {
            font-size: 1.4rem;
        }
    }
}

.paginator {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .count-input {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 2rem;
        .form-group {
            margin-bottom: 0;
        }
    }
    .count {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .pages {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .page {
            border: 1px solid #CCC;
            border-radius: 3px;
            padding: .5rem 1rem;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 1rem;
            &:hover {
                cursor: pointer;
                border-color: black;
            }
            &.active {
                border-color: black;
                color: black;
                background-color: #DEDEDE;
                font-weight: 400;
                &:hover {
                    cursor: default;
                }
            }
        }
    }
}

.sort-filter-query-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: transparent !important;
    .filters {
        height: 30px;
        flex: 1 1;
        justify-content: flex-end;
        display: flex;
        align-items: center;
        line-height: 1;
        .active-term {
            display: flex;
            align-items: center;
            font-weight: 700;
            background-color: #777;
            color: white;
            padding: .8rem;
            border-radius: 4px;
            margin: .5rem;
            svg {
                margin-left: .5rem;
            }
            &:hover {
                cursor: pointer;
                background-color: #CCC;
            }
        }
    }
    .search-box {
        width: 200px;
        flex-basis: 200px;
        position: relative;
        input {
            height: 3rem;
            border-radius: 1.5rem;
            border: thin solid $optimus-blue-10;
            background-color: $optimus-blue-10;
            color: $optimus-blue;
            padding-left: 1rem;
            padding-right: 1rem;
            position: relative;
            z-index: 1;
        }
        i, svg {
            position: absolute;
            right: .75em;
            top: .5em;
            height: 2rem;
            font-size: 1.5rem;
            color: $optimus-blue;
        }
    }
}

.pagination-wrapper {
    .pagination-controls {
        height: 4rem;
        .control {
            .control-icon {
                border: none;
                &:hover {
                    background: none !important;
                }
            }
        }
        .search-input-wrapper {
            input {
                padding: .5rem 1rem;
                font-size: 1.4rem;
            }
        }
    }

    .paginated-header,
    .paginated-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #CCC;
        height: 4rem;
        background-color: white;
        padding: .5rem;
        font-size: 1.2rem !important;
        a {
            font-weight: 400;
            font-size: 1.2rem;
        }
        .index {
            width: 30px;
            flex-basis: 0 0 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .main {
            max-width: 200px;
            flex: 1 1 auto;
            margin-right: 1rem;
            &.long {
                max-width: 300px;
                min-width: 200px;
            }
        }
        .action {
            min-width: 75px;
            max-width: 100px;
            flex: 1 1 auto;
            margin-right: 1rem;
        }
    }
    .paginated-header {
        background: none;
        font-weight: 700;

    }

    .paginator {
        margin-top: 1rem;
    }
    .muted {
        color: $medium-grey;
    }
    .action {
        width: 30px;
        .active {
            // color: $optimus-blue;
            &:hover {
                cursor: pointer;
            }
        }
    }
}

tr.deleted {
    td {
        background-color: $ex-light-grey;
        color: $medium-grey;
        text-decoration: line-through;
    }
}