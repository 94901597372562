@import '_colors.scss';

.dashboard-wrapper {
    display: flex;
    justify-content: space-between;
    .sidebar {
        margin-top: 1rem;
        width: $sidebarWidth;
    }
    .main {
        width: calc(100% - #{$sidebarWidth + $sidebarGutter});
    }
    @include respond-to-max('tablet') {
        flex-direction: column-reverse;
        .sidebar {
            width: 100%;
            margin-top: 1rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .actions {
                width: 100%;
            }
        }
        .main {
            width: 100%;
        }
    }
}

.columns {
    display: flex;
    flex-wrap: wrap;
    .box {
        padding: 1.25rem 1.5rem;
    }
    & > div {
        flex: 1 1 calc(50% - 2rem);
        width: calc(50% - 2rem);
        &:first-child {
            margin-right: 1rem;
        }
        &:last-child {
            margin-left: 1rem;
        }
    }
}

.tiles-row {
    display: flex;
    justify-content: space-between;
    & > div {
        margin-right: 1rem;
        &:last-child {
            margin-right: 0;
        }
    }
    @include respond-to-max('tablet') {
        flex-wrap: wrap;
        & > div {
            margin-right: 0;
            margin-bottom: 1rem;
            width: 49%;
        }
    }
    // @include respond-to-max('mobile') {
    //     & > div {
    //         width: 100%;
    //     }
    // }
}

.panel-tabs {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    margin-bottom: -.5rem;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    overflow: hidden;
    position: relative;
    display: flex;
    width: fit-content;
    z-index: 1;
    & > .panel-control {
        background-color: $light-grey;
        padding: 1rem 2rem;
        font-size: 2rem;
        color: $medium-grey;
        font-weight: 600;
        border-right: thin solid $optimus-blue-50;
        cursor: pointer;
        &:hover, &:focus {
            background-color: $offwhite;
            color: $optimus-blue-50;
        }
        &.active {
            pointer-events: none;
            background-color: white;
            color: $optimus-blue;
        }
        &:last-child {
            border-right: none;
        }
    }
}

.title-wrapper {
    margin-bottom: 1rem;
    margin-top: 3rem;
    h1 {
        font-size: 2.4rem;
        margin: 0 0 .75rem 0;
        font-weight: 600;
        text-transform: uppercase;
    }
    h2 {
        font-size: 1.6rem;
        font-weight: 400;
    }
    h3 {
        font-size: 1.4rem;
        font-weight: 600;
    }
    display: flex;
    justify-content: space-between;
    .actions {
        flex: 0 0 10rem;
    }
    &.section-title {
        margin-top: 1rem;
        align-items: flex-end;
        .buttons {
            margin-left: 1rem;
            span, a {
                &.btn {
                    font-size: 1rem;
                    padding: .75rem 1rem;
                    margin: 0 0 0 1rem;
                    &:first-child {
                        margin-left: 0;
                    }
                }
                svg, i {
                    margin-left: .5rem;
                }
                &:nth-child(n + 2) {
                    margin-left: 1rem;
                }
            }
            
        }
        .actions {
            & > span {
                width: 2.5rem;
                height: 2.5rem;
                svg, i {
                    font-size: 1rem;
                }
            }
        }
    }
}

@include respond-to-max('laptop') {
    .container {
        max-width: unset;
    }
}