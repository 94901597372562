// Anything truck specific as needed
.truck {
    .truck-header {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: space-between;
        box-shadow: 0px 0px 10px 5px $light-grey;
        @include box;
        .truck-details {
            display: flex;
            // flex: 0 0 calc(100% - 25rem);
            flex: 1 1 100%;
            justify-content: space-between;
            .truck-details-block, .truck-status-block {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                flex: 0 0 calc(50% - 1rem);
            }
        }
        .truck-details-block {
            .title-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
            }
            h1 {
                margin-bottom: 0;
                font-weight: 600;
            }
            h2 {
                font-weight: 300;
                font-size: 1.4rem;
                margin-bottom: 2rem;
            }
            .detail {
                display: flex;
                width: 100%;
                border-bottom: thin solid $light-grey;
                align-items: flex-start;
                margin-top: .5em;
                label {
                    flex: 0 0 10rem;
                    font-size: 1.2rem;
                    font-weight: 400 !important;
                    color: $medium-grey;
                }
            }
        }
        .status-detail {
            display: flex;
            padding-bottom: .5rem;
            margin-bottom: .5rem;
            align-items: baseline;
            .label {
                flex: 0 0 11rem;
                font-size: 1.4rem;
                font-weight: 600 !important;
                color: $medium-grey;
                margin-top: .5em;
            }
            .value-wrapper {
                span {
                    font-size: 1.6rem;
                    font-weight: 600;
                }
                .small {
                    font-size: 1.2rem;
                    font-weight: 300;
                }
                .info-wrapper {
                    color: $dark-grey;
                    font-size: 1rem;
                    text-align: left;
                    justify-content: flex-start;
                }
            }
            .form-control {
                color: $optimus-blue;
                font-weight: 600;
                margin-left: .5rem;
                border-bottom: thin dotted $optimus-blue;
                border-radius: 0;
            }
        }
        .truck-history-block {
            flex: 0 0 20rem;
            display: flex;
        }
    }
    .modal {
        .modal-backdrop {
            z-index: 12;
        }
    }
}

// Modal Table
.truck-estops-table {
    width: 100%;
}

// Fleet Page
.truck-options,
.active-trucks {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    .truck {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .25rem 1rem;
        border: 1px solid $optimus-blue;
        background-color: $optimus-blue-20;
        margin: 0 .5rem 1rem 0;
        border-radius: 4px;
        font-size: 1.5rem;
        font-weight: 600;
        &.active {
            background-color: $optimus-blue-50;
            color: white;
        }
        &:hover {
            cursor: pointer;
            background-color: $optimus-blue;
            transform: scale(1.1);
        }
    }
}
.active-trucks {
    .truck {
        &:hover {
            cursor: default;
            background-color: $optimus-blue-50;
        }
    }
}

.details-block {
    .instant-data-fields {
        display: flex;
        flex: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        .data-field {
            width: calc(33.33% - 2rem  - 2px);
            border: 1px solid $optimus-blue-50;
            background-color: $bg-blue;
            color: $optimus-blue;
            border-radius: 7px;
            padding: 1rem;
            margin-bottom: 1rem;
            margin-right: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .graph {
                flex: 0 0 20px;
            }
            .display {
                flex: 1 1 80px;
                height: 80px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 2.5rem;
                font-weight: 600;
            }
            .label {
                flex: 0 0 20px;
            }
        }
    }
}

// vin search
.vin-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include box;
    background-color: $light-grey;
    .vin-field {
        display: flex; 
        align-items: center;
        width: 100%;
        max-width: 400px;
        margin-right: 1rem;

        .vin-field-key {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: .5rem;
            margin-right: .5rem;
        }
        .vin-field-value {
            font-size: 1.5rem;
            font-weight: 300;
            padding: .5rem;
            width: auto;
            flex-grow: 1;
        }
    }
}