#breadcrumb {
    min-height: 1.75rem;
    margin-top: 3.5rem;
    margin-left: 1rem;
    a {
        color: $medium-grey;
        font-weight: 400;
        text-decoration: none;
        font-size: 1.2rem;
        &:after {
            content: '/';
            display: inline-block;
            font-size: .9em;
            margin: 0 .5em;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
        &:hover, &:focus {
            color: $optimus-blue;
            text-decoration: underline;
        }
    }
    @include respond-to-max('tablet') {
        display: none;
    }
}