@import url('//fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,600;0,700;1,300&display=swap');
@import "_colors";
/* Base text styling */
body, html {
    font-weight: 200;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
}

h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
    font-weight: 400;
    color: $optimus-blue;
}

h1, .h1 {
  // font-size: 2rem; 
  font-size: 2.5rem;
  line-height: 1.2;
  margin-bottom: .5em;
}

h2, .h2 {
  font-weight: 600;
  // font-size: 2.5rem;
  font-size: 2rem;
  line-height: 1.21875;
}

h3, .h3 {
  // font-size: 2rem;
  font-size: 1.5rem;
  line-height: 1.25;
}

h4, .h4 {
  font-size: 1.5rem;
}

p, a, ul, li {
  font-size: 1.6rem;
}

b, strong {
    font-weight: 800;
}

.clickable {
  color: $optimus-blue;
  font-weight: 500;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.alert, .error-notice {
  color: $optimus-red;
}

.invisible {
  display: none;
}

.sm-txt {
  font-size: 1.2rem !important;
}

.p-label {
  font-size: 1.2rem;
  text-transform: uppercase;
  color: $dark-grey;
  font-weight: 500;
}

a {
  color: $optimus-blue;
  font-weight: 600;
  text-decoration: none;
  &:hover, &:focus {
    color: black;
    text-decoration: underline;
  }
}

@include respond-to-max('laptop') {
  h1, .h1 {
    font-size: 2.15rem;
  }
}

@include respond-to-max('tablet') {
  h1, .h1 {
    text-align: center;
  }
}

@include respond-to-max('mobile') {
  h1, .h1 {
    text-align: center;
  }
}
