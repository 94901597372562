@import "_colors";

@mixin flexWidth($widths...) {
    @each $width in $widths {
        @if $width == 'auto' {
            &.flex-auto {
                flex: 1 1 auto;
            }
        } @else {
            &.flex-#{round($width)} {
                flex-basis: calc(#{percentage($width/100)} - 1rem);
            }
        }
    }
}

@mixin shadow {
    box-shadow: rgba(77,77,77,.2) 1px 1px 15px;
}

@mixin box {
  background-color: white;
  border-radius: .5rem;
//   @include shadow;
  padding: 3rem 2rem;
  position: relative;
  z-index: 2;
}

@mixin gradientBg {
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 10vh, $optimus-blue-20-hex 100vh);
    background-repeat: no-repeat;
    background-attachment: fixed;
}