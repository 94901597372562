#public-nav, #admin-nav {
    height: 6rem;
    position: relative;
    z-index: 10;
    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        img {
            height: 4rem;
        }
        .navigation-links {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            a, span {
                font-size: 1.4rem;
                font-weight: 600;
                padding: .5em;
                margin: 0 1em;
                display: block;
                color: $dark-grey;
                height: 100%;
                text-decoration: none;
                &.active, &:hover, &:focus {
                    color: $optimus-blue;
                }
            }
            &-group {
                position: relative;
                .navigation-parent {
                    display: flex;
                    align-items: center;
                    position: relative;
                    z-index: 2;
                    i, svg {
                        display: none;
                        margin-left: -1.5em;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                .navigation-children {
                    display: none;
                    position: absolute;
                    z-index: 1;
                    top: 3rem;
                    width: 20rem;
                    right: 1.5rem;
                    background-color: white;
                    border-top: 2px solid $optimus-blue;
                    padding: 1rem;
                    @include shadow;
                }
                &:hover, &:focus {
                    .navigation-parent {
                        cursor: pointer;
                        a {
                            color: $optimus-blue;
                        }
                    }
                    .navigation-children {
                        display: block;
                    }
                }
            }
            .btn {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
}

#admin-nav {
    .wrapper {
        .navbar-toggle {
            display: none;
            position: fixed;
            top: 0;
            right: 0;
            z-index: 1002;
            cursor: pointer;
            width: 40px;
            height: 32px;
            &.bars {
                position: relative;
                .top, .middle, .bottom {
                    position: absolute;
                    width: 40px;
                    height: 4px;
                    background-color: $optimus-blue;
                    border-radius: 2px;
                    transition: all 0.3s ease;
                    left: 0;
                }
                .top {
                    top: 0;
                }
                .middle {
                    top: calc(50% - 2px);
                    margin-left: 4px;
                }
                .bottom {
                    bottom: 0;
                }
            }
        }
        .navigation-links {
            a, span {
                font-size: 1.6rem;
            }
            &-group {
                &:hover, &:focus {
                    .navigation-parent {
                        cursor: pointer;
                        a {
                            color: $optimus-blue;
                        }
                    }
                    .navigation-children {
                        display: block;
                    }
                }
                &:last-child {
                    a {
                        margin: 0;
                    }
                }
            }
        }
    }
}

#app-nav {
    position: relative;
    z-index: 10;
    width: 250px;
    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        // margin-left: 5rem;
        width: 100%;
        .logo-wrapper {
            height: 40px;
            margin: 2rem 0;
            .logo {
                // width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .navbar-toggle {
            display: none;
            position: fixed;
            top: 0;
            right: 1rem;
            z-index: 1002;
            cursor: pointer;
            width: 40px;
            height: 24px;
            &.bars {
                position: relative;
                .top, .middle, .bottom {
                    position: absolute;
                    width: 28px;
                    height: 4px;
                    background-color: $optimus-blue;
                    border-radius: 2px;
                    transition: all 0.3s ease;
                    left: 0;
                }
                .top {
                    top: 0;
                }
                .middle {
                    top: calc(50% - 2px);
                }
                .bottom {
                    bottom: 0;
                }
            }
        }
        .navigation-links {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            .logo-wrapper {
                .hidden-logo {
                    display: none;
                }
            }
            a, span {
                font-size: 1.4rem;
                font-weight: 600;
                padding: .5em 0 .5em 1em;
                margin: .1em 0;
                display: block;
                color: $dark-grey;
                height: 100%;
                text-decoration: none;
                .svg-inline--fa {
                    margin-right: 1em;
                    width: 1.5em;
                }
                &.active, &:hover, &:focus {
                    color: $optimus-blue;
                    border-left: .3rem solid;
                    margin-left: -.3rem;
                }
            }
            &-group {
                width: 100%;
                position: relative;
                .navigation-parent {
                    display: flex;
                    align-items: center;
                    position: relative;
                    z-index: 2;
                    .fa-caret-down {
                        margin-left: .5em;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                .navigation-children {
                    display: none;
                    z-index: 1;
                    padding: .5rem;
                }
                &.open {
                    .navigation-children {
                        display: block;
                        position: relative;
                        margin-left: 2.5rem;
                        a, span {
                            font-size: 1.3rem;
                            font-weight: 400;
                            cursor: pointer;
                            &.active, &:hover, &:focus {
                                border-left: unset;
                                margin-left: unset;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to-max('laptop') {
    #admin-nav {
        .wrapper {
            position: relative;
            z-index: 1001;
            .navbar-toggle {
                display: block;
            }
            .navigation-links {
                position: fixed;
                flex-direction: column;
                background-color: $optimus-blue;
                width: 100vw;
                height: 100vh;
                top: 0;
                left: -100vw;
                justify-content: flex-start;
                align-items: flex-start;
                -moz-transition: left 0.5s ease;
                transition: left 0.5s ease;
                overflow-y: auto;
                padding-left: 1.5rem;
                padding-top: 3rem;
                a, span {
                    color: white;
                    &.active, &:hover, &:focus {
                        color: white;
                    }
                    &.active {
                        text-decoration: underline;
                        text-underline-offset: 4px;
                    }
                }
                .navigation-parent {
                    span, a {
                        color: white !important;
                    }
                    svg {
                        color: white;
                    }
                }
                .navigation-children {
                    a {
                        color: $optimus-blue !important;
                        margin-left: 40px;
                    }
                }
                &-group {
                    width: 100%;
                    &:hover, &:focus {
                        .navigation-parent {
                            a {
                                color: white;
                            }
                        }
                        .navigation-children {
                            display: none;
                        }
                    }
                    &:last-child {
                        a {
                            margin: 0 1em;
                        }
                    }
                    i, svg {
                        display: unset !important;
                    }
                    &.open {
                        .navigation-children {
                            display: block;
                            position: relative;
                            width: 100vw;
                            top: unset;
                            right: unset;
                            box-shadow: none;
                            .sign-out {
                                margin-left: 40px;
                                color: $optimus-blue;
                            }
                            a, span {
                                font-size: 1.6rem;
                                cursor: pointer;
                                &.active, &:hover, &:focus {
                                    color: white;
                                }
                            }
                        }
                    }
                }
                .navigation-children {
                    left: -15px;
                }
                
            }
        }
        &.expanded {
            .top {
                background-color: white !important;
                transform-origin: right;
                transform: rotate(-45deg);
            }

            .middle {
                opacity: 0;
            }

            .bottom {
                background-color: white !important;
                transform-origin: right;
                transform: rotate(45deg);
            }

            .navigation-links {
                left: 0;
            }
        }
    }
    
    #app-nav {
        width: 100%;
        .wrapper {
            flex-direction: row;
            position: relative;
            z-index: 1001;
            align-items: center;
            margin-left: 0;
            .logo-wrapper {
                .logo {
                    margin-left: 1rem;
                }
            }
            .navbar-toggle {
                display: block;
                top: 3rem;
                // right: 2rem;
                right: 0;
                &.bars {
                    position: absolute;
                }
            }
            .navigation-links {
                @include gradientBg;
                position: fixed;
                width: 100vw;
                height: 100vh;
                top: 0;
                left: -100vw;
                justify-content: flex-start;
                -moz-transition: left 0.5s ease;
                transition: left 0.5s ease;
                overflow-y: auto;
                padding-left: 1.5rem;
                padding-top: 2rem;
                .logo-wrapper {
                    margin: 0 0 2rem -.5rem;
                    .hidden-logo {
                        display: block;
                        margin-top: 0;
                        margin-left: 0;
                    }
                }
                a, span {
                    cursor: pointer;
                }
                &-group {
                    &.open {
                        .navigation-children {
                            width: 100vw;
                            top: unset;
                            right: unset;
                            box-shadow: none;
                            margin-left: 3em;
                        }
                    }
                }
            }
        }
        &.expanded {
            .top {
                background-color: $optimus-blue !important;
                transform-origin: right;
                transform: rotate(-45deg);
            }
            .middle {
                opacity: 0;
            }
            .bottom {
                background-color: $optimus-blue !important;
                transform-origin: right;
                transform: rotate(45deg);
            }
            .navigation-links {
                left: 0;
            }
        }
    }
}