// Render full version, then use minimal to strip down to detail version
.fuel-bar {
    color: $optimus-blue;
    font-size: 1.6rem;
    .warning-label {
        color: $optimus-red;
        font-weight: 400;
        font-size: 1rem;
        margin-left: 100px;
        text-align: center;
    }
    .wrapper {
        display: flex;
        align-items: baseline;
        .label {
            flex: 0 0 100px;
            font-weight: 600;
        }
        .graph-wrapper {
            flex: 1 1 calc(100% - 100px);
            display: flex;
            align-items: center;
            justify-content: space-between;
            .fuel-bar-block {
                background-color: $optimus-blue-10;
                position: relative;
                margin: 0 1rem;
                width: 100%;
                height: 2rem;
                border-radius: 1rem;
                .fuel-bar-fill {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 50%;
                    content: '';
                    display: block;
                    background-color: $optimus-blue;
                    border-top-left-radius: 1rem;
                    border-bottom-left-radius: 1rem;
                    &.full {
                        border-top-right-radius: 1rem;
                        border-bottom-right-radius: 1rem;
                    }
                    .fuel-bar-amount {
                        width: 6rem;
                        position: absolute;
                        padding: 1rem;
                        background: white;
                        border: 2px solid $optimus-blue;
                        border-radius: 1rem;
                        font-weight: 800;
                        top: -1rem;
                        right: -3rem;
                        line-height: 1;
                    }
                }
            }
            & > span {
                font-weight: 800;
                flex: 0 0 2rem;
                text-align: center;
            }
        }
    }
    .info-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 1.2rem;
        margin-top: 1rem;
        span {
            font-weight: 400;
        }
        .btn {
            margin-right: 0;
            margin-left: 1rem;
            margin-bottom: 0;
            margin-top: 0;
            font-size: 1rem;
        }
    }
    &.minimal {
        padding: .5rem 0;
        margin-bottom: .5rem;
        .wrapper {
            .label {
                flex: 0 0 10rem;
                font-size: 1.4rem;
                font-weight: 600 !important;
                color: $medium-grey;
            }
            .graph-wrapper {
                align-items: center;
                justify-content: space-between;
                margin: 0 .5rem;
                .fuel-bar-block {
                    margin: 0 1rem;
                    height: 1rem;
                    border-radius: 1rem;
                    .fuel-bar-fill {
                        .fuel-bar-amount {
                            width: fit-content;
                            padding: .5rem;
                            font-size: 1.2rem;
                            top: -.85rem;
                        }
                    }
                }
            }
        }
        .info-wrapper {
            margin-top: .75rem;
            color: $dark-grey;
            font-size: 1rem;
            padding-left: 10rem;
            text-align: left;
            justify-content: flex-start;
        }
    }
}